import React from 'react';
import PropTypes from 'prop-types';

const Plate = ({
    firstNumber,
    lastNumber,
    stateName,
    stateLabel,
    handleClick
}) => {
    const ref = React.useRef(null);

    React.useEffect(() => {
        ref.current.addEventListener('click', () => {
            const label = ref.current.querySelector('label');
            if (label) {
                handleClick(label.getAttribute('data-state'));
            }
        });
    }, [handleClick]);

    return (
        <div className="plate" ref={ref}>
            <figure id={`${stateLabel}-figure`}>
                <figcaption>
                    {firstNumber}
                    <br />
                    {lastNumber}
                </figcaption>
            </figure>
            <label htmlFor={`${stateLabel}-figure`} data-state={stateName}>
                {stateLabel}
            </label>
        </div>
    );
};

export default Plate;

Plate.propTypes = {
    firstNumber: PropTypes.string.isRequired,
    lastNumber: PropTypes.string.isRequired,
    stateName: PropTypes.string.isRequired,
    stateLabel: PropTypes.string.isRequired,
    handleClick: PropTypes.func.isRequired
};
