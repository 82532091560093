import React from 'react';
import PropTypes from 'prop-types';

import useFetch from '@components/Hooks/useFetch';
import { firstLetterUpperCase } from '@components/Utilities';

const extraNewCategory = ['Electric Car', 'Electric Bike', 'Electric Scooter'];

const onlyUsedCategory = [
    'Plane',
    'Bicycle',
    'Taxi',
    'Truck',
    'Bus',
    'Tractor'
];

const onlyUsedNewCategory = [...extraNewCategory, ...onlyUsedCategory];

const Select = ({
    api,
    name,
    values,
    className,
    selectValue,
    onChange,
    updateValidation,
    processApiData,
    label,
    inputProps
}) => {
    const [value, setValue] = React.useState(selectValue);
    const [options, setOptions] = React.useState(values);

    const fetchConfig = React.useMemo(
        () => ({
            type: 'GET',
            url: api,
            processApiData
        }),
        [api, processApiData]
    );

    const { loading, data } = useFetch(fetchConfig);

    React.useEffect(() => {
        if (loading) return;

        if (data) {
            setOptions(prevState => ({
                ...prevState,
                ...data
            }));
        }
    }, [loading, data]);

    React.useEffect(() => {
        if (!api) setOptions(values);
    }, [api, values]);

    React.useEffect(() => {
        setValue(selectValue);
    }, [selectValue]);

    const handleChange = event => {
        event.preventDefault();
        setValue(event.target.value);
        if (onChange) onChange(event);
        if (updateValidation)
            updateValidation({
                name: event.target.name,
                value: event.target.value
            });
    };

    const isDisabled = React.useCallback(item => {
        if (['Top Products', 'All Products'].indexOf(item) !== -1)
            return 'disabled';
        return '';
    }, []);

    const getStyle = React.useCallback(
        item => {
            if (name.match('newcategory')) {
                return {
                    display: onlyUsedCategory.indexOf(item) > -1 ? 'none' : ''
                };
            }
            return {
                display:
                    name.match('(feature|lease|exchange)category') &&
                    onlyUsedNewCategory.indexOf(item) > -1
                        ? 'none'
                        : ''
            };
        },
        [name]
    );

    const getValue = React.useCallback(
        (key, item) => {
            return name.match('(|new|feature|lease|exchange)category')
                ? key
                : item;
        },
        [name]
    );

    return (
        <select
            {...inputProps}
            className={className}
            name={name}
            onChange={handleChange}
            value={value}
        >
            <option value="">
                {label ||
                    `Select ${firstLetterUpperCase(
                        /** for multi value input */
                        name.replace('[]', '')
                    )}`}
            </option>
            {Object.entries(options).map(([key, item]) => {
                return (
                    <option
                        key={`${item}-${key}-key`}
                        disabled={isDisabled(item)}
                        style={getStyle(item)}
                        value={getValue(key, item)}
                    >
                        {item}
                    </option>
                );
            })}
        </select>
    );
};

Select.propTypes = {
    api: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    values: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    name: PropTypes.string.isRequired,
    className: PropTypes.string.isRequired,
    selectValue: PropTypes.string,
    onChange: PropTypes.func,
    updateValidation: PropTypes.func,
    processApiData: PropTypes.func,
    label: PropTypes.string,
    // eslint-disable-next-line react/forbid-prop-types
    inputProps: PropTypes.object
};

Select.defaultProps = {
    api: '',
    label: '',
    values: [],
    inputProps: {},
    selectValue: '',
    onChange: null,
    updateValidation: null,
    processApiData: null
};

export default Select;
