import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from '@eo-locale/react';
import Select from '@components/Forms/partials/Select';
import { updateLocation } from '@app/layouts/header/HeaderActions';
import Plate from './Plate';

import './modalLocation.scss';

const Modal = () => {
    const dispatch = useDispatch();

    const location = useSelector(state => state.header.selectedLocation);

    const setLocation = value => {
        dispatch(updateLocation(value));
    };

    const handleClick = event => {
        setLocation(event.target.value);
    };

    return (
        <div>
            <div>
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">
                            <Text
                                id="Please_Confirm_Your_Location"
                                defaultMessage="Please Confirm Your Location"
                            />
                        </h5>
                        <button
                            id="closeLocationModal"
                            type="button"
                            className="close"
                            data-dismiss="modal"
                            aria-label="Close"
                        >
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div className="modal-body location">
                        <div className="row">
                            <div className="col col-sm-7">
                                <div className="form-group">
                                    <label htmlFor="exampleInputEmail1">
                                        <Text
                                            id="Most_Frequently_Selected_Cities"
                                            defaultMessage="Most Frequently Selected Cities"
                                        />
                                    </label>
                                    <div className="text-center">
                                        <Plate
                                            firstNumber="DL 4S M"
                                            lastNumber="4432"
                                            stateName="Delhi"
                                            stateLabel="Delhi"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="HR 01"
                                            lastNumber="F132"
                                            stateName="Gurgaon"
                                            stateLabel="Gurgaon"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="UP 01"
                                            lastNumber="F132"
                                            stateName="Noida"
                                            stateLabel="Noida"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="KA 01"
                                            lastNumber="F132"
                                            stateName="Bangalore"
                                            stateLabel="Bangalore"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="TN 02"
                                            lastNumber="C570"
                                            stateName="Chennai"
                                            stateLabel="Chennai"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="TS 01"
                                            lastNumber="F132"
                                            stateName="Hyderabad"
                                            stateLabel="Hyderabad"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="MH 01"
                                            lastNumber="F132"
                                            stateName="Mumbai"
                                            stateLabel="Mumbai"
                                            handleClick={setLocation}
                                        />
                                        <Plate
                                            firstNumber="WB 01"
                                            lastNumber="F132"
                                            stateName="Kolkata"
                                            stateLabel="Kolkata"
                                            handleClick={setLocation}
                                        />
                                    </div>
                                </div>

                                <div className="form-group p-3">
                                    <label htmlFor="select-your-city">
                                        <Text
                                            id="Select Your City"
                                            defaultMessage="Select Your City"
                                        />
                                    </label>
                                    <Select
                                        className="form-control"
                                        name="city"
                                        api="/get_city_list"
                                        values={[]}
                                        onChange={handleClick}
                                        selectValue={location}
                                    />
                                </div>
                            </div>
                            <div className="col col-sm-5 d-none d-sm-block">
                                <figure className="sprite-home lion lg" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Modal;
